<template>
    <div class="news_all dirr">
        <v-container>

            <div class="title">
                <span><img
                        :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
                <p class="tl" v-if="cat_id == 'all'">{{ $t("all_News") }}</p>
                <p class="tl" v-else>{{cat_title}}</p>
            </div>
            <div class="bCon d-none d-sm-flex" style="flex-wrap:wrap;">
                <div class="Fgroup" v-if="impoNews_skeleton">

                    <v-layout row>
                        <v-flex sm4 xs12 md4 lg4 style="padding:5px 5px 5px 20px; margin:7px 0;"
                            class="d-sm-none d-md-flex">
                            <v-skeleton-loader width="320" min-width="320"
                                type="card, paragraph, paragraph, paragraph, sentences, paragraph"></v-skeleton-loader>
                        </v-flex>
                        <v-flex sm12 xs12 md8 lg8 style="padding:8px; margin:7px 0;">
                            <div class="items">
                                <v-layout row>
                                    <v-flex sm4 xs6 md4 lg4 v-for="n in 6" :key="n" style="padding:5px;">
                                        <v-skeleton-loader width="260" min-width="260" type="card"></v-skeleton-loader>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </v-flex>
                    </v-layout>
                </div>

                <div class="Fgroup" v-else>
                    <v-layout row>
                        <v-flex v-if="impoNewsRes[0]" sm4 xs12 md4 xlg4 style="padding:5px 5px 5px 5px; margin:7px 0;"
                            class="d-sm-none d-md-flex">
                            <router-link :to="'/new/'+ impoNewsRes[0].id + '?&lang=' +$i18n.locale" class="Fitem">
                                <div class="img">



                                    <v-img v-if="impoNewsRes[0].id > 652"
                                        :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+impoNewsRes[0].image"
                                        :src="'https://alkafeel.net/news/mcroped96/275/'+impoNewsRes[0].image"
                                        style="border-radius: 4px;">
                                    </v-img>

                                    <v-img v-else
                                        :lazy-src="'https://alkafeel.net/alkafeelnews/up2/'+impoNewsRes[0].image"
                                        :src="'https://alkafeel.net/alkafeelnews/up2/'+impoNewsRes[0].image"
                                        style="border-radius: 4px;">
                                    </v-img>


                                    <div class="icon" v-if="impoNewsRes[0].video">
                                        <svg width="54" height="55" viewBox="0 0 54 55" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="27.1089" cy="27.7103" r="26.8329" fill="#B1BD52"
                                                fill-opacity="0.8" />
                                            <path
                                                d="M39.0253 26.8443C39.692 27.2292 39.692 28.1915 39.0253 28.5764L21.9007 38.4633C21.234 38.8482 20.4007 38.3671 20.4007 37.5973V17.8235C20.4007 17.0537 21.234 16.5725 21.9007 16.9574L39.0253 26.8443Z"
                                                fill="white" />
                                        </svg>
                                    </div>
                                    <div class="division d-none d-sm-flex" v-if="impoNewsRes[0].cat_title && cat_id == 'all' && $i18n.locale=='ar'">
                                        <span>
                                            <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M8.02845 1.45919L4.97888 4.53174L5.48659 5.04327L8.53616 1.97072L8.02845 1.45919Z"
                                                    fill="white" />
                                                <path
                                                    d="M8.03117 3.505L5.99536 5.55615L6.50307 6.06769L8.53888 4.01654L8.03117 3.505Z"
                                                    fill="white" />
                                                <path
                                                    d="M11.077 4.53217L10.5693 5.0437L11.077 5.55523L11.5848 5.0437L11.077 4.53217Z"
                                                    fill="white" />
                                                <path
                                                    d="M9.04648 4.52865L7.01233 6.57812L7.52004 7.08966L9.55419 5.04018L9.04648 4.52865Z"
                                                    fill="white" />
                                                <path
                                                    d="M9.04742 6.57759L8.02869 7.604L8.5364 8.11554L9.55513 7.08913L9.04742 6.57759Z"
                                                    fill="white" />
                                                <path
                                                    d="M8.03116 3.50629L10.5697 6.06396L11.0774 5.55243L8.53887 2.99476L8.03116 3.50629Z"
                                                    fill="white" />
                                                <path
                                                    d="M9.96658 3.41116L11.5859 5.04272L12.0936 4.53119L10.4743 2.89963L9.96658 3.41116Z"
                                                    fill="white" />
                                                <path
                                                    d="M8.03144 5.55329L9.55457 7.08789L10.0623 6.57636L8.53915 5.04175L8.03144 5.55329Z"
                                                    fill="white" />
                                                <path
                                                    d="M4.98347 4.53205L6.50659 6.06665L7.0143 5.55512L5.49117 4.02051L4.98347 4.53205Z"
                                                    fill="white" />
                                                <path
                                                    d="M8.03116 1.46083L9.45972 2.90015L9.96743 2.38861L8.53887 0.949293L8.03116 1.46083Z"
                                                    fill="white" />
                                                <path
                                                    d="M1.42382 9.13839L4.47339 12.2109L4.9811 11.6994L1.93153 8.62685L1.42382 9.13839Z"
                                                    fill="white" />
                                                <path
                                                    d="M3.45455 9.13488L5.49036 11.186L5.99807 10.6745L3.96226 8.62334L3.45455 9.13488Z"
                                                    fill="white" />
                                                <path
                                                    d="M4.47484 6.0661L4.98254 6.57764L5.49025 6.0661L4.98254 5.55457L4.47484 6.0661Z"
                                                    fill="white" />
                                                <path
                                                    d="M4.47281 8.1097L6.50696 10.1592L7.01467 9.64765L4.98052 7.59816L4.47281 8.1097Z"
                                                    fill="white" />
                                                <path
                                                    d="M6.50446 8.11031L7.52319 9.13672L8.0309 8.62519L7.01217 7.59877L6.50446 8.11031Z"
                                                    fill="white" />
                                                <path
                                                    d="M3.45548 9.13482L5.99402 6.57715L5.48631 6.06561L2.94777 8.62328L3.45548 9.13482Z"
                                                    fill="white" />
                                                <path
                                                    d="M3.36355 7.18478L4.98291 5.55322L4.4752 5.04169L2.85585 6.67325L3.36355 7.18478Z"
                                                    fill="white" />
                                                <path
                                                    d="M5.48737 9.13396L7.0105 7.59937L6.50279 7.08783L4.97967 8.62243L5.48737 9.13396Z"
                                                    fill="white" />
                                                <path
                                                    d="M4.47355 12.2076L5.9021 10.7683L5.39439 10.2568L3.96584 11.6961L4.47355 12.2076Z"
                                                    fill="white" />
                                                <path
                                                    d="M1.42618 9.13683L2.85474 7.69751L2.34703 7.18598L0.918477 8.62529L1.42618 9.13683Z"
                                                    fill="white" />
                                                <path
                                                    d="M9.0454 15.7955L12.095 12.7229L11.5873 12.2114L8.53769 15.2839L9.0454 15.7955Z"
                                                    fill="white" />
                                                <path
                                                    d="M9.04329 13.7487L11.0791 11.6975L10.5714 11.186L8.53558 13.2371L9.04329 13.7487Z"
                                                    fill="white" />
                                                <path
                                                    d="M5.99888 12.722L6.50659 12.2104L5.99888 11.6989L5.49118 12.2104L5.99888 12.722Z"
                                                    fill="white" />
                                                <path
                                                    d="M8.02725 12.7245L10.0614 10.675L9.55369 10.1635L7.51954 12.213L8.02725 12.7245Z"
                                                    fill="white" />
                                                <path
                                                    d="M8.0279 10.678L9.04663 9.65161L8.53892 9.14008L7.52019 10.1665L8.0279 10.678Z"
                                                    fill="white" />
                                                <path
                                                    d="M9.04342 13.7471L6.50488 11.1895L5.99717 11.701L8.53572 14.2587L9.04342 13.7471Z"
                                                    fill="white" />
                                                <path
                                                    d="M7.10862 13.843L5.48926 12.2114L4.98155 12.723L6.60091 14.3545L7.10862 13.843Z"
                                                    fill="white" />
                                                <path
                                                    d="M9.04193 11.7009L7.5188 10.1663L7.01109 10.6778L8.53421 12.2124L9.04193 11.7009Z"
                                                    fill="white" />
                                                <path
                                                    d="M12.0929 12.7225L10.6643 11.2832L10.1566 11.7947L11.5851 13.2341L12.0929 12.7225Z"
                                                    fill="white" />
                                                <path
                                                    d="M9.04476 15.7928L7.61621 14.3535L7.1085 14.8651L8.53706 16.3044L9.04476 15.7928Z"
                                                    fill="white" />
                                                <path
                                                    d="M15.6521 8.11577L12.6025 5.04321L12.0948 5.55475L15.1444 8.6273L15.6521 8.11577Z"
                                                    fill="white" />
                                                <path
                                                    d="M13.6206 8.12073L11.5848 6.06958L11.0771 6.58112L13.1129 8.63227L13.6206 8.12073Z"
                                                    fill="white" />
                                                <path
                                                    d="M12.601 11.1866L12.0933 10.675L11.5856 11.1866L12.0933 11.6981L12.601 11.1866Z"
                                                    fill="white" />
                                                <path
                                                    d="M12.6042 9.14103L10.5701 7.09155L10.0624 7.60309L12.0965 9.65256L12.6042 9.14103Z"
                                                    fill="white" />
                                                <path
                                                    d="M10.5715 9.1414L9.55273 8.11499L9.04502 8.62653L10.0638 9.65294L10.5715 9.1414Z"
                                                    fill="white" />
                                                <path
                                                    d="M13.62 8.11933L11.0814 10.677L11.5891 11.1885L14.1277 8.63087L13.62 8.11933Z"
                                                    fill="white" />
                                                <path
                                                    d="M13.714 10.0684L12.0946 11.7L12.6023 12.2115L14.2217 10.5799L13.714 10.0684Z"
                                                    fill="white" />
                                                <path
                                                    d="M11.5893 8.1197L10.0662 9.6543L10.5739 10.1658L12.097 8.63123L11.5893 8.1197Z"
                                                    fill="white" />
                                                <path
                                                    d="M12.6014 5.0475L11.1729 6.48682L11.6806 6.99835L13.1091 5.55904L12.6014 5.0475Z"
                                                    fill="white" />
                                                <path
                                                    d="M15.6501 8.11781L14.2216 9.55713L14.7293 10.0687L16.1578 8.62934L15.6501 8.11781Z"
                                                    fill="white" />
                                                <path
                                                    d="M9.04579 6.57916L8.53809 6.06763L7.52433 7.08902L8.03204 7.60056L9.04579 6.57916Z"
                                                    fill="white" />
                                                <path
                                                    d="M9.54128 10.1536L9.03357 9.64209L8.01981 10.6635L8.52752 11.175L9.54128 10.1536Z"
                                                    fill="white" />
                                                <path
                                                    d="M7.52276 9.13676L6.50403 8.11035L5.99632 8.62189L7.01506 9.6483L7.52276 9.13676Z"
                                                    fill="white" />
                                                <path
                                                    d="M11.0805 8.62993L10.0618 7.60352L9.55406 8.11505L10.5728 9.14146L11.0805 8.62993Z"
                                                    fill="white" />
                                            </svg>
                                        </span>
                                        <p class="tl">{{impoNewsRes[0].cat_title}}</p>
                                    </div>
                                </div>
                                <p class="tl ta" v-html="impoNewsRes[0].title"></p>
                                <p v-if="impoNewsRes[0].text" :class="$i18n.locale=='ar' ? 'txx' : 'tx'" class="ta"
                                    v-html="removebbcode(impoNewsRes[0].text)+ ' ...'"></p>
                                <div class="date">
                                    <span><img
                                            :src="$vuetify.theme.dark ? '/assets/img/date_small_white.svg' : '/assets/img/date_small_dark.svg'" /></span>
                                    <p class="tl">{{ impoNewsRes[0].time|FormatDate }}</p>
                                </div>
                            </router-link>
                        </v-flex>
                        <v-flex sm12 xs12 md8 lg8 style="padding:8px;">
                            <div class="items">
                                <v-layout row>
                                    <v-flex sm4 xs6 md4 xlg4 v-for="i in impoNewsRes.slice(1,7)" :key="i"
                                        style="padding:5px;">
                                        <router-link :to="'/new/'+ i.id + '?&lang=' +$i18n.locale" class="Oitem">
                                            <div class="img">
                                                <v-img v-if="i.id > 652"
                                                    :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image"
                                                    :src="'https://alkafeel.net/news/mcroped96/275/'+i.image"
                                                    style="border-radius: 4px;">
                                                </v-img>

                                                <v-img v-else
                                                    :lazy-src="'https://alkafeel.net/alkafeelnews/up2/'+i.image"
                                                    :src="'https://alkafeel.net/alkafeelnews/up2/'+i.image"
                                                    style="border-radius: 4px;">
                                                </v-img>



                                                <div class="icon" v-if="i.video">
                                                    <svg width="54" height="55" viewBox="0 0 54 55" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="27.1089" cy="27.7103" r="26.8329" fill="#B1BD52"
                                                            fill-opacity="0.8" />
                                                        <path
                                                            d="M39.0253 26.8443C39.692 27.2292 39.692 28.1915 39.0253 28.5764L21.9007 38.4633C21.234 38.8482 20.4007 38.3671 20.4007 37.5973V17.8235C20.4007 17.0537 21.234 16.5725 21.9007 16.9574L39.0253 26.8443Z"
                                                            fill="white" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <p class="tl ta" v-html="$options.filters.subStrtitle(i.title)"></p>
                                            <div class="details">
                                                <div class="division" v-if="impoNewsRes[0].cat_title && cat_id == 'all' && $i18n.locale=='ar'">
                                                    <span>
                                                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M5.82581 0.417925L3.32703 2.93555L3.74304 3.35469L6.24183 0.837072L5.82581 0.417925Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M5.82791 2.09421L4.15979 3.7749L4.5758 4.19405L6.24393 2.51335L5.82791 2.09421Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M8.32385 2.93608L7.90784 3.35522L8.32385 3.77437L8.73986 3.35522L8.32385 2.93608Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M6.65981 2.93298L4.99304 4.6123L5.40905 5.03145L7.07582 3.35213L6.65981 2.93298Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M6.66067 4.61185L5.82593 5.45288L6.24194 5.87203L7.07668 5.03099L6.66067 4.61185Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M5.82802 2.09543L7.90808 4.19116L8.32409 3.77202L6.24403 1.67628L5.82802 2.09543Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M7.41396 2.01761L8.74084 3.35449L9.15686 2.93535L7.82997 1.59846L7.41396 2.01761Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M5.82814 3.77259L7.07617 5.03003L7.49218 4.61088L6.24415 3.35344L5.82814 3.77259Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M3.33082 2.93567L4.57886 4.19312L4.99487 3.77397L3.74683 2.51653L3.33082 2.93567Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M5.82799 0.419269L6.99854 1.59863L7.41455 1.17949L6.244 0.000121169L5.82799 0.419269Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M0.413931 6.71041L2.91272 9.22803L3.32873 8.80888L0.829942 6.29126L0.413931 6.71041Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M2.07785 6.707L3.74597 8.3877L4.16198 7.96855L2.49386 6.28785L2.07785 6.707Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M2.9137 4.19316L3.32971 4.6123L3.74572 4.19316L3.32971 3.77401L2.9137 4.19316Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M2.91246 5.86755L4.57922 7.54688L4.99523 7.12773L3.32847 5.4484L2.91246 5.86755Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M4.57688 5.86795L5.41162 6.70898L5.82763 6.28984L4.99289 5.44881L4.57688 5.86795Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M2.07851 6.70706L4.15857 4.61133L3.74256 4.19218L1.6625 6.28791L2.07851 6.70706Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M2.00319 5.10959L3.33008 3.77271L2.91407 3.35356L1.58718 4.69044L2.00319 5.10959Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M3.74366 6.70617L4.9917 5.44873L4.57569 5.02959L3.32765 6.28702L3.74366 6.70617Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M2.91271 9.22526L4.08325 8.0459L3.66724 7.62675L2.4967 8.80612L2.91271 9.22526Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M0.416005 6.70939L1.58655 5.53003L1.17054 5.11088L-5.83808e-06 6.29025L0.416005 6.70939Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M6.65905 12.1651L9.15784 9.64746L8.74182 9.22831L6.24304 11.7459L6.65905 12.1651Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M6.65719 10.4881L8.32532 8.80737L7.9093 8.38823L6.24118 10.0689L6.65719 10.4881Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M4.16285 9.64644L4.57886 9.22729L4.16285 8.80815L3.74683 9.2273L4.16285 9.64644Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M5.82493 9.64856L7.4917 7.96924L7.07569 7.55009L5.40892 9.22942L5.82493 9.64856Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M5.82541 7.97165L6.66016 7.13062L6.24414 6.71147L5.4094 7.5525L5.82541 7.97165Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M6.65733 10.4864L4.57727 8.39062L4.16126 8.80977L6.24132 10.9055L6.65733 10.4864Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M5.07213 10.5649L3.74524 9.22803L3.32923 9.64717L4.65611 10.9841L5.07213 10.5649Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M6.65636 8.80993L5.40833 7.55249L4.99231 7.97164L6.24035 9.22908L6.65636 8.80993Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M9.15601 9.64689L7.98547 8.46753L7.56946 8.88668L8.74 10.066L9.15601 9.64689Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M6.6587 12.1633L5.48816 10.9839L5.07215 11.403L6.24269 12.5824L6.6587 12.1633Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M12.0726 5.87236L9.57385 3.35474L9.15784 3.77388L11.6566 6.29151L12.0726 5.87236Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M10.408 5.87625L8.73987 4.19556L8.32386 4.6147L9.99198 6.2954L10.408 5.87625Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M9.57226 8.38863L9.15625 7.96948L8.74024 8.38863L9.15625 8.80778L9.57226 8.38863Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M9.57509 6.71228L7.90833 5.03296L7.49231 5.4521L9.15908 7.13143L9.57509 6.71228Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M7.90957 6.71262L7.07483 5.87158L6.65882 6.29073L7.49356 7.13176L7.90957 6.71262Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M10.4072 5.87521L8.32715 7.97095L8.74316 8.3901L10.8232 6.29436L10.4072 5.87521Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M10.4844 7.47219L9.15747 8.80908L9.57348 9.22823L10.9004 7.89134L10.4844 7.47219Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M8.74327 5.87562L7.49524 7.13306L7.91125 7.5522L9.15929 6.29476L8.74327 5.87562Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M9.57264 3.35799L8.4021 4.53735L8.81811 4.9565L9.98865 3.77714L9.57264 3.35799Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M12.0709 5.87434L10.9004 7.05371L11.3164 7.47286L12.4869 6.29349L12.0709 5.87434Z"
                                                                fill="#B1BD52" />
                                                            <path
                                                                d="M6.65918 4.61324L6.24316 4.19409L5.4125 5.03101L5.82851 5.45016L6.65918 4.61324Z"
                                                                fill="white" />
                                                            <path
                                                                d="M7.06543 7.54219L6.64941 7.12305L5.81875 7.95997L6.23476 8.37912L7.06543 7.54219Z"
                                                                fill="white" />
                                                            <path
                                                                d="M5.41152 6.70895L4.57678 5.86792L4.16077 6.28707L4.99551 7.1281L5.41152 6.70895Z"
                                                                fill="white" />
                                                            <path
                                                                d="M8.32668 6.29342L7.49194 5.45239L7.07593 5.87154L7.91067 6.71257L8.32668 6.29342Z"
                                                                fill="white" />
                                                        </svg>
                                                    </span>
                                                    <p class="tl">{{i.cat_title}}</p>
                                                </div>
                                                <div class="date">
                                                    <span><img
                                                            :src="$vuetify.theme.dark ? '/assets/img/date_mini_white.svg' : '/assets/img/date_mini_dark.svg'" /></span>
                                                    <p class="tl">{{ i.time|FormatDate }}</p>
                                                </div>
                                            </div>
                                        </router-link>
                                    </v-flex>
                                </v-layout>
                            </div>
                        </v-flex>
                    </v-layout>
                </div>
                <div class="Sgroup">
                    <div class="items">
                        <v-layout row>
                            <v-flex sm4 xs6 md4 lg4 xl3 v-for="i in impoNewsRes.slice(7,16)" :key="i"
                                style="padding:8px; margin:7px 0;">
                                <router-link :to="'/new/'+ i.id + '?&lang=' +$i18n.locale" class="item">
                                    <div class="img">
                                        <img v-if="i.id && i.id > 652"
                                            :src="'https://alkafeel.net/news/mcroped96/275/'+i.image"
                                            style="border-radius: 4px;    width: 100%;" />

                                        <img v-else-if="i.id" :src="'https://alkafeel.net/alkafeelnews/up2/'+i.image"
                                            style="border-radius: 4px;    width: 100%;" />



                                        <div class="division " v-if="i.cat_title && cat_id == 'all' && $i18n.locale=='ar'">
                                            <span>
                                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M8.02845 1.45919L4.97888 4.53174L5.48659 5.04327L8.53616 1.97072L8.02845 1.45919Z"
                                                        fill="white" />
                                                    <path
                                                        d="M8.03117 3.505L5.99536 5.55615L6.50307 6.06769L8.53888 4.01654L8.03117 3.505Z"
                                                        fill="white" />
                                                    <path
                                                        d="M11.077 4.53217L10.5693 5.0437L11.077 5.55523L11.5848 5.0437L11.077 4.53217Z"
                                                        fill="white" />
                                                    <path
                                                        d="M9.04648 4.52865L7.01233 6.57812L7.52004 7.08966L9.55419 5.04018L9.04648 4.52865Z"
                                                        fill="white" />
                                                    <path
                                                        d="M9.04742 6.57759L8.02869 7.604L8.5364 8.11554L9.55513 7.08913L9.04742 6.57759Z"
                                                        fill="white" />
                                                    <path
                                                        d="M8.03116 3.50629L10.5697 6.06396L11.0774 5.55243L8.53887 2.99476L8.03116 3.50629Z"
                                                        fill="white" />
                                                    <path
                                                        d="M9.96658 3.41116L11.5859 5.04272L12.0936 4.53119L10.4743 2.89963L9.96658 3.41116Z"
                                                        fill="white" />
                                                    <path
                                                        d="M8.03144 5.55329L9.55457 7.08789L10.0623 6.57636L8.53915 5.04175L8.03144 5.55329Z"
                                                        fill="white" />
                                                    <path
                                                        d="M4.98347 4.53205L6.50659 6.06665L7.0143 5.55512L5.49117 4.02051L4.98347 4.53205Z"
                                                        fill="white" />
                                                    <path
                                                        d="M8.03116 1.46083L9.45972 2.90015L9.96743 2.38861L8.53887 0.949293L8.03116 1.46083Z"
                                                        fill="white" />
                                                    <path
                                                        d="M1.42382 9.13839L4.47339 12.2109L4.9811 11.6994L1.93153 8.62685L1.42382 9.13839Z"
                                                        fill="white" />
                                                    <path
                                                        d="M3.45455 9.13488L5.49036 11.186L5.99807 10.6745L3.96226 8.62334L3.45455 9.13488Z"
                                                        fill="white" />
                                                    <path
                                                        d="M4.47484 6.0661L4.98254 6.57764L5.49025 6.0661L4.98254 5.55457L4.47484 6.0661Z"
                                                        fill="white" />
                                                    <path
                                                        d="M4.47281 8.1097L6.50696 10.1592L7.01467 9.64765L4.98052 7.59816L4.47281 8.1097Z"
                                                        fill="white" />
                                                    <path
                                                        d="M6.50446 8.11031L7.52319 9.13672L8.0309 8.62519L7.01217 7.59877L6.50446 8.11031Z"
                                                        fill="white" />
                                                    <path
                                                        d="M3.45548 9.13482L5.99402 6.57715L5.48631 6.06561L2.94777 8.62328L3.45548 9.13482Z"
                                                        fill="white" />
                                                    <path
                                                        d="M3.36355 7.18478L4.98291 5.55322L4.4752 5.04169L2.85585 6.67325L3.36355 7.18478Z"
                                                        fill="white" />
                                                    <path
                                                        d="M5.48737 9.13396L7.0105 7.59937L6.50279 7.08783L4.97967 8.62243L5.48737 9.13396Z"
                                                        fill="white" />
                                                    <path
                                                        d="M4.47355 12.2076L5.9021 10.7683L5.39439 10.2568L3.96584 11.6961L4.47355 12.2076Z"
                                                        fill="white" />
                                                    <path
                                                        d="M1.42618 9.13683L2.85474 7.69751L2.34703 7.18598L0.918477 8.62529L1.42618 9.13683Z"
                                                        fill="white" />
                                                    <path
                                                        d="M9.0454 15.7955L12.095 12.7229L11.5873 12.2114L8.53769 15.2839L9.0454 15.7955Z"
                                                        fill="white" />
                                                    <path
                                                        d="M9.04329 13.7487L11.0791 11.6975L10.5714 11.186L8.53558 13.2371L9.04329 13.7487Z"
                                                        fill="white" />
                                                    <path
                                                        d="M5.99888 12.722L6.50659 12.2104L5.99888 11.6989L5.49118 12.2104L5.99888 12.722Z"
                                                        fill="white" />
                                                    <path
                                                        d="M8.02725 12.7245L10.0614 10.675L9.55369 10.1635L7.51954 12.213L8.02725 12.7245Z"
                                                        fill="white" />
                                                    <path
                                                        d="M8.0279 10.678L9.04663 9.65161L8.53892 9.14008L7.52019 10.1665L8.0279 10.678Z"
                                                        fill="white" />
                                                    <path
                                                        d="M9.04342 13.7471L6.50488 11.1895L5.99717 11.701L8.53572 14.2587L9.04342 13.7471Z"
                                                        fill="white" />
                                                    <path
                                                        d="M7.10862 13.843L5.48926 12.2114L4.98155 12.723L6.60091 14.3545L7.10862 13.843Z"
                                                        fill="white" />
                                                    <path
                                                        d="M9.04193 11.7009L7.5188 10.1663L7.01109 10.6778L8.53421 12.2124L9.04193 11.7009Z"
                                                        fill="white" />
                                                    <path
                                                        d="M12.0929 12.7225L10.6643 11.2832L10.1566 11.7947L11.5851 13.2341L12.0929 12.7225Z"
                                                        fill="white" />
                                                    <path
                                                        d="M9.04476 15.7928L7.61621 14.3535L7.1085 14.8651L8.53706 16.3044L9.04476 15.7928Z"
                                                        fill="white" />
                                                    <path
                                                        d="M15.6521 8.11577L12.6025 5.04321L12.0948 5.55475L15.1444 8.6273L15.6521 8.11577Z"
                                                        fill="white" />
                                                    <path
                                                        d="M13.6206 8.12073L11.5848 6.06958L11.0771 6.58112L13.1129 8.63227L13.6206 8.12073Z"
                                                        fill="white" />
                                                    <path
                                                        d="M12.601 11.1866L12.0933 10.675L11.5856 11.1866L12.0933 11.6981L12.601 11.1866Z"
                                                        fill="white" />
                                                    <path
                                                        d="M12.6042 9.14103L10.5701 7.09155L10.0624 7.60309L12.0965 9.65256L12.6042 9.14103Z"
                                                        fill="white" />
                                                    <path
                                                        d="M10.5715 9.1414L9.55273 8.11499L9.04502 8.62653L10.0638 9.65294L10.5715 9.1414Z"
                                                        fill="white" />
                                                    <path
                                                        d="M13.62 8.11933L11.0814 10.677L11.5891 11.1885L14.1277 8.63087L13.62 8.11933Z"
                                                        fill="white" />
                                                    <path
                                                        d="M13.714 10.0684L12.0946 11.7L12.6023 12.2115L14.2217 10.5799L13.714 10.0684Z"
                                                        fill="white" />
                                                    <path
                                                        d="M11.5893 8.1197L10.0662 9.6543L10.5739 10.1658L12.097 8.63123L11.5893 8.1197Z"
                                                        fill="white" />
                                                    <path
                                                        d="M12.6014 5.0475L11.1729 6.48682L11.6806 6.99835L13.1091 5.55904L12.6014 5.0475Z"
                                                        fill="white" />
                                                    <path
                                                        d="M15.6501 8.11781L14.2216 9.55713L14.7293 10.0687L16.1578 8.62934L15.6501 8.11781Z"
                                                        fill="white" />
                                                    <path
                                                        d="M9.04579 6.57916L8.53809 6.06763L7.52433 7.08902L8.03204 7.60056L9.04579 6.57916Z"
                                                        fill="white" />
                                                    <path
                                                        d="M9.54128 10.1536L9.03357 9.64209L8.01981 10.6635L8.52752 11.175L9.54128 10.1536Z"
                                                        fill="white" />
                                                    <path
                                                        d="M7.52276 9.13676L6.50403 8.11035L5.99632 8.62189L7.01506 9.6483L7.52276 9.13676Z"
                                                        fill="white" />
                                                    <path
                                                        d="M11.0805 8.62993L10.0618 7.60352L9.55406 8.11505L10.5728 9.14146L11.0805 8.62993Z"
                                                        fill="white" />
                                                </svg>
                                            </span>
                                            <p class="tl">{{i.cat_title}}</p>
                                        </div>
                                        <div class="icon" v-if="i.video">
                                            <svg width="54" height="55" viewBox="0 0 54 55" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="27.1089" cy="27.7103" r="26.8329" fill="#B1BD52"
                                                    fill-opacity="0.8" />
                                                <path
                                                    d="M39.0253 26.8443C39.692 27.2292 39.692 28.1915 39.0253 28.5764L21.9007 38.4633C21.234 38.8482 20.4007 38.3671 20.4007 37.5973V17.8235C20.4007 17.0537 21.234 16.5725 21.9007 16.9574L39.0253 26.8443Z"
                                                    fill="white" />
                                            </svg>
                                        </div>
                                    </div>
                                    <p class="tl ta" v-html="$options.filters.subStrNewsTitle(i.title)"></p>
                                    <!-- <p :class="$i18n.locale=='ar' ? 'txx' : 'tx'" class="ta">{{i.text|subStrtext2}}</p> -->
                                    <div class="date">
                                        <span><img
                                                :src="$vuetify.theme.dark ? '/assets/img/date_small_white.svg' : '/assets/img/date_small_dark.svg'" /></span>
                                        <p class="tl">{{ i.time|FormatDate }}</p>
                                    </div>
                                </router-link>
                            </v-flex>
                        </v-layout>
                    </div>
                </div>
                <div class="btnCon">
                    <!-- <v-btn v-if="getMorenews" width="150" height="40" class="ma-2 tl"
                        style="justify-self:center !important;" :loading="loading" color="rgba(177, 189, 82, 1)"
                        @click="grtNews()">
                        <p class="getMore alistSub tl">{{ $t('view_more') }}</p>
                    </v-btn> -->
                    <v-pagination class="pagination"  next-icon='next-icon' @input="next" prev-icon="prev-icon" total-visible="20" v-model="page" 
                        color="rgba(177, 189, 82, 1)"  :length="pageCount">
                    </v-pagination>
                </div>
            </div>
            <div class="mCon d-flex d-sm-none" v-if="impoNewsRes[0]">
                <router-link :to="'/new/'+ impoNewsRes[0].id + '?&lang=' +$i18n.locale" class="Fitem">
                    <div class="img">
                        <v-img v-if="impoNewsRes[0].id > 652" :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+impoNewsRes[0].image"
                            :src="'https://alkafeel.net/news/mcroped96/275/'+impoNewsRes[0].image"
                            style="border-radius: 4px;">
                        </v-img>

                        <v-img v-else :lazy-src="'https://alkafeel.net/alkafeelnews/up2/'+impoNewsRes[0].image"
                            :src="'https://alkafeel.net/alkafeelnews/up2/'+impoNewsRes[0].image"
                            style="border-radius: 4px;">
                        </v-img>

                      
                        <div class="icon" v-if="impoNewsRes[0].video">
                            <svg width="54" height="55" viewBox="0 0 54 55" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="27.1089" cy="27.7103" r="26.8329" fill="#B1BD52" fill-opacity="0.8" />
                                <path
                                    d="M39.0253 26.8443C39.692 27.2292 39.692 28.1915 39.0253 28.5764L21.9007 38.4633C21.234 38.8482 20.4007 38.3671 20.4007 37.5973V17.8235C20.4007 17.0537 21.234 16.5725 21.9007 16.9574L39.0253 26.8443Z"
                                    fill="white" />
                            </svg>
                        </div>
                        <div class="division d-none d-sm-flex">
                            <span>
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M8.02845 1.45919L4.97888 4.53174L5.48659 5.04327L8.53616 1.97072L8.02845 1.45919Z"
                                        fill="white" />
                                    <path
                                        d="M8.03117 3.505L5.99536 5.55615L6.50307 6.06769L8.53888 4.01654L8.03117 3.505Z"
                                        fill="white" />
                                    <path
                                        d="M11.077 4.53217L10.5693 5.0437L11.077 5.55523L11.5848 5.0437L11.077 4.53217Z"
                                        fill="white" />
                                    <path
                                        d="M9.04648 4.52865L7.01233 6.57812L7.52004 7.08966L9.55419 5.04018L9.04648 4.52865Z"
                                        fill="white" />
                                    <path
                                        d="M9.04742 6.57759L8.02869 7.604L8.5364 8.11554L9.55513 7.08913L9.04742 6.57759Z"
                                        fill="white" />
                                    <path
                                        d="M8.03116 3.50629L10.5697 6.06396L11.0774 5.55243L8.53887 2.99476L8.03116 3.50629Z"
                                        fill="white" />
                                    <path
                                        d="M9.96658 3.41116L11.5859 5.04272L12.0936 4.53119L10.4743 2.89963L9.96658 3.41116Z"
                                        fill="white" />
                                    <path
                                        d="M8.03144 5.55329L9.55457 7.08789L10.0623 6.57636L8.53915 5.04175L8.03144 5.55329Z"
                                        fill="white" />
                                    <path
                                        d="M4.98347 4.53205L6.50659 6.06665L7.0143 5.55512L5.49117 4.02051L4.98347 4.53205Z"
                                        fill="white" />
                                    <path
                                        d="M8.03116 1.46083L9.45972 2.90015L9.96743 2.38861L8.53887 0.949293L8.03116 1.46083Z"
                                        fill="white" />
                                    <path
                                        d="M1.42382 9.13839L4.47339 12.2109L4.9811 11.6994L1.93153 8.62685L1.42382 9.13839Z"
                                        fill="white" />
                                    <path
                                        d="M3.45455 9.13488L5.49036 11.186L5.99807 10.6745L3.96226 8.62334L3.45455 9.13488Z"
                                        fill="white" />
                                    <path
                                        d="M4.47484 6.0661L4.98254 6.57764L5.49025 6.0661L4.98254 5.55457L4.47484 6.0661Z"
                                        fill="white" />
                                    <path
                                        d="M4.47281 8.1097L6.50696 10.1592L7.01467 9.64765L4.98052 7.59816L4.47281 8.1097Z"
                                        fill="white" />
                                    <path
                                        d="M6.50446 8.11031L7.52319 9.13672L8.0309 8.62519L7.01217 7.59877L6.50446 8.11031Z"
                                        fill="white" />
                                    <path
                                        d="M3.45548 9.13482L5.99402 6.57715L5.48631 6.06561L2.94777 8.62328L3.45548 9.13482Z"
                                        fill="white" />
                                    <path
                                        d="M3.36355 7.18478L4.98291 5.55322L4.4752 5.04169L2.85585 6.67325L3.36355 7.18478Z"
                                        fill="white" />
                                    <path
                                        d="M5.48737 9.13396L7.0105 7.59937L6.50279 7.08783L4.97967 8.62243L5.48737 9.13396Z"
                                        fill="white" />
                                    <path
                                        d="M4.47355 12.2076L5.9021 10.7683L5.39439 10.2568L3.96584 11.6961L4.47355 12.2076Z"
                                        fill="white" />
                                    <path
                                        d="M1.42618 9.13683L2.85474 7.69751L2.34703 7.18598L0.918477 8.62529L1.42618 9.13683Z"
                                        fill="white" />
                                    <path
                                        d="M9.0454 15.7955L12.095 12.7229L11.5873 12.2114L8.53769 15.2839L9.0454 15.7955Z"
                                        fill="white" />
                                    <path
                                        d="M9.04329 13.7487L11.0791 11.6975L10.5714 11.186L8.53558 13.2371L9.04329 13.7487Z"
                                        fill="white" />
                                    <path
                                        d="M5.99888 12.722L6.50659 12.2104L5.99888 11.6989L5.49118 12.2104L5.99888 12.722Z"
                                        fill="white" />
                                    <path
                                        d="M8.02725 12.7245L10.0614 10.675L9.55369 10.1635L7.51954 12.213L8.02725 12.7245Z"
                                        fill="white" />
                                    <path
                                        d="M8.0279 10.678L9.04663 9.65161L8.53892 9.14008L7.52019 10.1665L8.0279 10.678Z"
                                        fill="white" />
                                    <path
                                        d="M9.04342 13.7471L6.50488 11.1895L5.99717 11.701L8.53572 14.2587L9.04342 13.7471Z"
                                        fill="white" />
                                    <path
                                        d="M7.10862 13.843L5.48926 12.2114L4.98155 12.723L6.60091 14.3545L7.10862 13.843Z"
                                        fill="white" />
                                    <path
                                        d="M9.04193 11.7009L7.5188 10.1663L7.01109 10.6778L8.53421 12.2124L9.04193 11.7009Z"
                                        fill="white" />
                                    <path
                                        d="M12.0929 12.7225L10.6643 11.2832L10.1566 11.7947L11.5851 13.2341L12.0929 12.7225Z"
                                        fill="white" />
                                    <path
                                        d="M9.04476 15.7928L7.61621 14.3535L7.1085 14.8651L8.53706 16.3044L9.04476 15.7928Z"
                                        fill="white" />
                                    <path
                                        d="M15.6521 8.11577L12.6025 5.04321L12.0948 5.55475L15.1444 8.6273L15.6521 8.11577Z"
                                        fill="white" />
                                    <path
                                        d="M13.6206 8.12073L11.5848 6.06958L11.0771 6.58112L13.1129 8.63227L13.6206 8.12073Z"
                                        fill="white" />
                                    <path
                                        d="M12.601 11.1866L12.0933 10.675L11.5856 11.1866L12.0933 11.6981L12.601 11.1866Z"
                                        fill="white" />
                                    <path
                                        d="M12.6042 9.14103L10.5701 7.09155L10.0624 7.60309L12.0965 9.65256L12.6042 9.14103Z"
                                        fill="white" />
                                    <path
                                        d="M10.5715 9.1414L9.55273 8.11499L9.04502 8.62653L10.0638 9.65294L10.5715 9.1414Z"
                                        fill="white" />
                                    <path
                                        d="M13.62 8.11933L11.0814 10.677L11.5891 11.1885L14.1277 8.63087L13.62 8.11933Z"
                                        fill="white" />
                                    <path
                                        d="M13.714 10.0684L12.0946 11.7L12.6023 12.2115L14.2217 10.5799L13.714 10.0684Z"
                                        fill="white" />
                                    <path
                                        d="M11.5893 8.1197L10.0662 9.6543L10.5739 10.1658L12.097 8.63123L11.5893 8.1197Z"
                                        fill="white" />
                                    <path
                                        d="M12.6014 5.0475L11.1729 6.48682L11.6806 6.99835L13.1091 5.55904L12.6014 5.0475Z"
                                        fill="white" />
                                    <path
                                        d="M15.6501 8.11781L14.2216 9.55713L14.7293 10.0687L16.1578 8.62934L15.6501 8.11781Z"
                                        fill="white" />
                                    <path
                                        d="M9.04579 6.57916L8.53809 6.06763L7.52433 7.08902L8.03204 7.60056L9.04579 6.57916Z"
                                        fill="white" />
                                    <path
                                        d="M9.54128 10.1536L9.03357 9.64209L8.01981 10.6635L8.52752 11.175L9.54128 10.1536Z"
                                        fill="white" />
                                    <path
                                        d="M7.52276 9.13676L6.50403 8.11035L5.99632 8.62189L7.01506 9.6483L7.52276 9.13676Z"
                                        fill="white" />
                                    <path
                                        d="M11.0805 8.62993L10.0618 7.60352L9.55406 8.11505L10.5728 9.14146L11.0805 8.62993Z"
                                        fill="white" />
                                </svg>
                            </span>
                            <p class="tl">{{impoNewsRes[0].cat_title}}</p>
                        </div>
                    </div>
                    <p class="tl ta" v-html="impoNewsRes[0].title"></p>
                    
                  
                </router-link>
                <router-link v-for="(i,n) in impoNewsRes.slice(1)" :key="i" :id="'div' + n++" :ref="'div' + n++" :to="'/new/'+ i.id + '?&lang=' +$i18n.locale"
                    class="Fitem">
                    <div class="img" style="margin-bottom:-20px;">
                        <v-img v-if="impoNewsRes[0].id > 652" :lazy-src="'https://alkafeel.net/news/mcroped96/100/'+i.image"
                            :src="'https://alkafeel.net/news/mcroped96/275/'+i.image"
                            style="border-radius: 4px; height: 100%; width: 100%;">
                        </v-img>

                        <v-img v-else :lazy-src="'https://alkafeel.net/alkafeelnews/up2/'+i.image"
                            :src="'https://alkafeel.net/alkafeelnews/up2/'+i.image"
                            style="border-radius: 4px; height: 100%; width: 100%;">
                        </v-img>

                        
                        <div class="home_main_new_cat" style="bottom: 34px !important;">

                            <!-- {{moment.unix(item.time).locale($i18n.locale).fromNow()}} -->

                            {{ i.time|FormatDate }}
                        </div>
                    </div>
                    <p :class="'tl ta ' + $store.state.direction" v-html="i.title"></p>
                    
                </router-link>
                <!-- <div class="btnCon">
                    <v-btn v-if="getMorenews" width="150" height="40" class="ma-2 tl"
                        style="align-self:center !important;" :loading="loading" color="rgba(177, 189, 82, 1)"
                        @click="grtMobNews()">
                        <p class="alistSub tl">{{ $t('view_more') }}</p>
                    </v-btn>
                </div> -->
                <v-pagination class="pagination" @input="next" total-visible="20" next-icon='next-icon' prev-icon="prev-icon" v-model="page" 
                    color="rgba(177, 189, 82, 1)" :length="pageCount">
                </v-pagination>
            </div>

            <news-search></news-search>


        </v-container>
    </div>
</template>

<script>
    import searchVue from './search.vue';
    export default {
        data() {
            return {
                page: 1,
                pageCount: 0,
                current_page: 1,
                last_page: 0,
                NewsRes: [{
                    "id": "",
                    "title": "",
                    "text": "",
                    "time": ""
                }],
                cat_title: '',
                impoNewsRes: [{
                    "id": "",
                    "title": "",
                    "text": "",
                    "time": ""
                }],
                NewsPage: 1,
                MobNewsPage: 1,
                cat_id: this.$route.params.cat,
                getMorenews: true,
                loader: null,
                loading: false,
                impoNews_skeleton: true,
                offsetTop: 0,
                axios_load: false
            }
        },
        components: {
            'news-search': searchVue
        },
        directives: {},
        computed: {},
        updated() {
            // document.title = this.$t("news") + ' | ' + this.cat_title;
            
        },
        computed: {
            // selected: function () {
           

              

              
            //     return this.getMoreitems();
            // }
        },
        watch: {
            
            // selected: 'search by sub_cat_id',
            '$route'(to, from) {
                let cat_id = to.params.cat;
                
                if (this.cat_id !== cat_id) {
                    this.cat_id = cat_id;
                    const url = '/newscat/'+cat_id+'?lang='+this.$i18n.locale;
                    history.pushState({}, '', url);
                    this.grtMobNews();
                    this.page=parseInt(0);
                }
                document.title = this.$t("news");
            },

            // selected: 'search by sub_cat_id',
            // '$route'(to, from) {
            //     let cat_id = to.params.cat;
            //     this.cat_id = cat_id;
            //     const url = '/newscat/'+cat_id+'?lang='+this.$i18n.locale;
            //     history.pushState({}, '', url);
            //     this.grtMobNews();
            //     document.title = this.$t("news");
            // },
         
        },
        mounted() {
            this.grtMobNews();
            const queryString = window.location.search;


                const urlParams = new URLSearchParams(queryString);
                const page = urlParams.get('page')
                if(page !==null){
                    this.page=parseInt(page);
                }
                


            document.title = this.$t("news");
        },
        created() {
            
        
        },
        methods: {
            next(){
                if (this.current_page <= this.last_page) {
                    this.current_page = this.page;
                    this.$router.push({ path: window.location.search, query: { page:this.current_page }})
                    setTimeout(() => this.grtMobNews(), 200);
                }
            },
            grtMobNews() {
                this.loader = 'loading';
                const queryString = window.location.search;
                const urlParams = new URLSearchParams(queryString);
                const page = urlParams.get('page');
                const more = urlParams.get('more');
                this.$axios.get("articles/GetLast/16/" + this.cat_id + "?page=" + page, {})
                .then(res => {
                    this.axios_load = false;
                    this.impoNewsRes = res.data.data;
                    setTimeout(() => this.impoNews_skeleton = false, 500);
                    this.cat_title = res.data.data[0].cat_title;
                    window.scrollTo(0, 0);
                    this.last_page = res.data.last_page;
                    this.pageCount = res.data.last_page;

                    
                    if (res.data.length == 0) {
                        this.getMorenews = false;
                    }
                    
                })
                .catch(() => {});
                if (this.isMobileDevice() && page == null && more == 1) {
                    setTimeout(() => document.getElementById('div6').scrollIntoView(), 1000);
                }
            },
            isMobileDevice() {
                var check = false;
                (function (a) {
                    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i
                        .test(a) ||
                        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i
                        .test(a.substr(0, 4))) check = true;
                })(navigator.userAgent || navigator.vendor || window.opera);
                return check;
            },
            removebbcode(x) {
                if (x) {
                    return this.stripHTML(x.replace(/\[\/?[^\]]*\]/g, ''))
                }
            },
            stripHTML (value) {
                const div = document.createElement('div')
                div.innerHTML = value
                const text = div.textContent || div.innerText || ''
                return text
            }
        },
        filters: {
            subStrtitle: function (string) {
                if (string != null) {
                    return string.substring(0, 70) + '...';
                }
            },
            subStrtext: function (string) {
                if (string != null) {
                    return string.substring(0, 250) + '...';
                }
            },
            subStrtext2: function (string) {
                if (string != null) {
                    return string.substring(0, 120) + '...';
                }
            },
            subStrNewsTitle: function (string) {
                if (string != null) {
                    return string.substring(0, 60) + '...';
                }
            },
            FormatDate: function (unixTime) {
                let d = new Date(unixTime * 1000);
                let ye = new Intl.DateTimeFormat('en', {
                    year: 'numeric'
                }).format(d);
                let mo = new Intl.DateTimeFormat('en', {
                    month: '2-digit'
                }).format(d);
                let da = new Intl.DateTimeFormat('en', {
                    day: '2-digit'
                }).format(d);
                return (`${da}-${mo}-${ye}`);
            },
        },
    }
</script>

